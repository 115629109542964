<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="account-group"></b-icon>
          <span>{{ $ml.get('titles_accounts') }}</span>
        </h4>
        <b-button tag="router-link" type="is-primary" outlined :to="{ path: `/rooms/create` }">
          <span>{{ $ml.get('common_create') }}</span>
        </b-button>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="name" :label="$ml.get('accounts_name')" sortable searchable cell-class="leftal" v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="bookmaker" :label="$ml.get('accounts_bookmaker')" width="100" sortable searchable cell-class="leftal">
            <template #searchable="props">
              <b-select v-model="props.filters[props.column.field]" expanded>
                <option></option>
                <option v-for="option in bookmakers" :value="option" :key="option">
                  {{ option }}
                </option>
              </b-select>
            </template>
            <template v-slot="props">
              {{ props.row.bookmaker }}
            </template>
          </b-table-column>
          <b-table-column field="state" width="100" :label="$ml.get('accounts_state')" sortable cell-class="leftal">
            <template v-slot="props">
              <Status v-bind:state="props.row.state"/>
            </template>
          </b-table-column>
          <b-table-column field="ends_at" width="140" :label="$ml.get('accounts_ends_at')" sortable cell-class="leftal">
            <template v-slot="props">
              {{ props.row.ends_at }}
            </template>
          </b-table-column>
          <b-table-column field="balance" :label="$ml.get('accounts_balance')" width="100" sortable cell-class="leftal" v-slot="props">
            <Money v-bind:amount="props.row.balance" v-bind:currency="props.row.currency"
                   v-if="props.row.balance !== null"/>
          </b-table-column>
          <b-table-column field="placed_balance" :label="$ml.get('accounts_placed_balance')" width="100" sortable cell-class="leftal"
                          v-slot="props">
            <Money v-bind:amount="props.row.placed_balance" v-bind:currency="props.row.currency"
                   v-if="props.row.placed_balance !== null"/>
          </b-table-column>
          <b-table-column field="bets" :label="$ml.get('accounts_placed_bets')" sortable cell-class="leftal" v-slot="props">
            {{ props.row.bets }}
          </b-table-column>
          <b-table-column field="last_bet_at" :label="$ml.get('accounts_last_bet')" sortable cell-class="leftal" v-slot="props">
            {{ props.row.last_bet_at_hr }}
          </b-table-column>
          <b-table-column v-slot="props" width="250">
            <ul class="horizontal">
              <li>
                <b-button size="is-small" type="is-success" outlined @click="buy_(props.row.id, 7)" v-if="props.row.can_be_bought"
                          :title="$ml.get('accounts_buy_link_7')">
                  <b-icon icon="cart-plus" size="is-small"></b-icon>
                  <span>7</span>
                </b-button>
              </li>
              <li>
                <b-button size="is-small" type="is-warning" outlined @click="buy_(props.row.id, 30)" v-if="props.row.can_be_bought"
                          :title="$ml.get('accounts_buy_link_30')">
                  <b-icon icon="cart-plus" size="is-small"></b-icon>
                  <span>30</span>
                </b-button>
              </li>
              <li>
                <b-button size="is-small" type="is-success" outlined @click="rdp_(props.row.id, props.row.name)" v-if="props.row.can_be_downloaded"
                          :title="$ml.get('accounts_rdp_link')">
                  <b-icon icon="play" size="is-small"></b-icon>
                </b-button>
              </li>
              <li>
                <router-link :to="{ path: `/rooms/create?id=${props.row.id}` }" v-if="props.row.can_be_edited">
                  <b-button type="is-info" size="is-small" outlined>
                    <b-icon icon="pencil" size="is-small"></b-icon>
                  </b-button>
                </router-link>
<!--                <b-button type="is-info" disabled size="is-small" outlined v-if="!props.row.can_be_edited">-->
<!--                  <b-icon icon="pencil" size="is-small"></b-icon>-->
<!--                </b-button>-->
              </li>
<!--              <li>-->
<!--                <b-button size="is-small" type="is-success" outlined @click="start_(props.row.id)"-->
<!--                          :title="$ml.get('accounts_start_link')" v-if="props.row.can_be_started">-->
<!--                  <b-icon icon="play" size="is-small"></b-icon>-->
<!--                </b-button>-->
<!--                <b-button size="is-small" type="is-success" disabled outlined v-if="!props.row.can_be_started">-->
<!--                  <b-icon icon="play" size="is-small"></b-icon>-->
<!--                </b-button>-->
<!--              </li>-->
<!--              <li>-->
<!--                <b-button size="is-small" type="is-warning" outlined @click="stop_(props.row.id)"-->
<!--                          :title="$ml.get('accounts_stop_link')" v-if="props.row.can_be_stopped">-->
<!--                  <b-icon icon="stop" size="is-small"></b-icon>-->
<!--                </b-button>-->
<!--                <b-button size="is-small" type="is-warning" outlined disabled v-if="!props.row.can_be_stopped">-->
<!--                  <b-icon icon="stop" size="is-small"></b-icon>-->
<!--                </b-button>-->
<!--              </li>-->
              <li>
                <b-button tag="router-link" :title="$ml.get('common_statistic')" size="is-small" outlined type="is-success"
                          :to="`/statistic/room?id=${props.row.id}`">
                  <b-icon size="is-small" icon="chart-bar"></b-icon>
                </b-button>
              </li>
              <li>
                <b-button size="is-small" type="is-danger" outlined @click="delete_(props.row.id)"
                          :title="$ml.get('common_delete')" v-if="props.row.can_be_deleted">
                  <b-icon icon="delete" size="is-small"></b-icon>
                </b-button>
                <b-button size="is-small" type="is-danger" outlined disabled v-if="!props.row.can_be_deleted">
                  <b-icon icon="delete" size="is-small"></b-icon>
                </b-button>
              </li>
            </ul>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <b-modal v-model="rdp_modal.show" :can-cancel="rdp_modal.can_cancel">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ rdp_modal.title }}</p>
        </header>
        <section class="modal-card-body leftal">
          <span v-if="rdp_modal.text.length > 0">{{ rdp_modal.text }}<br></span>
          <span v-if="rdp_modal.link">{{ $ml.get('accounts_rdp_link_prefix') }}: <a :href="rdp_modal.link" target="_blank">{{
              rdp_modal.link
            }}</a><br></span>
          <span v-if="rdp_modal.login">{{ $ml.get('accounts_rdp_login') }}: <b>{{ rdp_modal.login }}</b><br></span>
          <span v-if="rdp_modal.password">{{ $ml.get('accounts_rdp_password') }}: <b>{{ rdp_modal.password }}</b><br></span>
        </section>
        <footer class="modal-card-foot">
          <b-button :label="$ml.get('accounts_rdp_close')" @click="close_rdp(rdp_modal.room_id)" v-if="rdp_modal.full_screen"/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import Money from "@/components/Money";
import Status from "@/components/Status";
import {mapGetters} from "vuex";

export default {
  name: "Index",
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      loading: false,
      update_ival: null,
      items: [],
      rdp_modal: {
        show: false,
        can_cancel: false,
        title: this.$ml.get('accounts_rdp_await_title'),
        text: this.$ml.get('accounts_rdp_await_text'),
        login: '',
        password: '',
        link: ''
      }
    }
  },
  components: {
    Status,
    Money
  },
  computed: {
    ...mapGetters('data', ['bookmakers']),
  },
  methods: {
    c_code(id) {
      this.$buefy.dialog.prompt({
        message: this.$ml.get('accounts_code_title'),
        trapFocus: true,
        confirmText: this.$ml.get('common_send'),
        cancelText: this.$ml.get('common_close'),
        onConfirm: (value) => {
          this.loading = true
          Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `rooms/confirmation`, {
            room: {
              id: id,
              code: value
            }
          }).then(resp => {
            this.retrieve()
            this.flashMessage.success({
              title: this.$ml.get('common_request_success'),
            })
          }).catch(err => {
            this.flashMessage.error({
              title: this.$ml.get('common_request_failed'),
              message: this.$ml.get('common_request_failed_message'),
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    // start_: function (id) {
    //   if (confirm(this.$ml.get('accounts_start_confirm'))) {
    //     this.loading = true
    //     Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `rooms/start`, {room: {id: id}}).then(resp => {
    //       this.retrieve()
    //       this.flashMessage.success({
    //         title: this.$ml.get('common_request_success'),
    //       })
    //     }).catch(err => {
    //       this.flashMessage.error({
    //         title: this.$ml.get('common_request_failed'),
    //         message: this.$ml.get('common_request_failed_message'),
    //       })
    //     }).finally(() => {
    //       this.loading = false
    //     })
    //   }
    // },
    buy_: function (id, days) {
      if (confirm(this.$ml.get('accounts_buy_confirm_' + days))) {
        this.loading = true
        Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `rooms/buy`, {room: {id: id, days: days}}).then(resp => {
          this.retrieve()
          this.flashMessage.success({
            title: this.$ml.get('accounts_buy_success'),
          })
        }).catch(err => {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }).finally(() => {
          this.loading = false
        })
      }
    },
    rdp_: function (id, name) {
      this.loading = true
      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `rooms/rdp`, {room: {id: id}}, {responseType: 'blob'}).then(resp => {
        const blob = new Blob([resp.data], { type: 'application/zip' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
        this.flashMessage.success({
          title: this.$ml.get('common_request_success'),
        })
      }).catch(err => {
        console.log(err);
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
      // this.rdp_modal = {
      //   show: true,
      //   can_cancel: false,
      //   title: this.$ml.get('accounts_rdp_await_title'),
      //   text: this.$ml.get('accounts_rdp_await_text'),
      //   // login: '',
      //   // password: '',
      //   // link: ''
      // }
      // Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `rooms/rdp`, {room: {id: id}}).then(resp => {
      //   this.rdp_modal.show = true
      //   setTimeout(() => {
      //     this.rdp_modal.title = this.$ml.get('accounts_rdp_done_title')
      //     this.rdp_modal.text = this.$ml.get('accounts_rdp_done_text')
      //     // this.rdp_modal.login = resp.data.result.login
      //     // this.rdp_modal.password = resp.data.result.password
      //     // this.rdp_modal.link = resp.data.result.link
      //     this.rdp_modal.can_cancel = true
      //   }, 40000)
      // }).catch(err => {
      //   this.rdp_modal.show = true
      //   this.rdp_modal.text = err.response.data.result.id[0]
      //   this.rdp_modal.can_cancel = true
      // })
    },
    // stop_: function (id) {
    //   if (confirm(this.$ml.get('accounts_stop_confirm'))) {
    //     this.loading = true
    //     Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `rooms/stop`, {room: {id: id}}).then(resp => {
    //       this.retrieve()
    //       this.flashMessage.success({
    //         title: this.$ml.get('common_request_success'),
    //       })
    //     }).catch(err => {
    //       this.flashMessage.error({
    //         title: this.$ml.get('common_request_failed'),
    //         message: this.$ml.get('common_request_failed_message'),
    //       })
    //     }).finally(() => {
    //       this.loading = false
    //     })
    //   }
    // },
    delete_: function (id) {
      if (confirm(this.$ml.get('accounts_delete_confirm'))) {
        this.loading = true
        Vue.prototype.$http.delete(process.env.VUE_APP_API_HOST + `rooms/index?id=${id}`).then(resp => {
          this.retrieve()
          this.flashMessage.success({
            title: this.$ml.get('common_request_success'),
          })
        }).catch(err => {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }).finally(() => {
          this.loading = false
        })
      }
    },
    retrieve: function () {
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `rooms/index`).then(resp => {
        this.items = resp.data.result.items
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.loading = true
    this.retrieve()
    this.update_ival = setInterval(() => {
      this.retrieve()
    }, 120000)
  },
  beforeDestroy() {
    if (this.update_ival) {
      clearInterval(this.update_ival)
    }
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_accounts')
    }
  }
}
</script>

<style scoped>
</style>